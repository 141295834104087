// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-faq-js": () => import("./../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-home-about-tratto-js": () => import("./../src/pages/home/AboutTratto.js" /* webpackChunkName: "component---src-pages-home-about-tratto-js" */),
  "component---src-pages-home-create-links-js": () => import("./../src/pages/home/CreateLinks.js" /* webpackChunkName: "component---src-pages-home-create-links-js" */),
  "component---src-pages-home-create-reminder-js": () => import("./../src/pages/home/CreateReminder.js" /* webpackChunkName: "component---src-pages-home-create-reminder-js" */),
  "component---src-pages-home-elements-js": () => import("./../src/pages/home/Elements.js" /* webpackChunkName: "component---src-pages-home-elements-js" */),
  "component---src-pages-home-hero-js": () => import("./../src/pages/home/Hero.js" /* webpackChunkName: "component---src-pages-home-hero-js" */),
  "component---src-pages-home-payment-methods-js": () => import("./../src/pages/home/PaymentMethods.js" /* webpackChunkName: "component---src-pages-home-payment-methods-js" */),
  "component---src-pages-home-receive-fast-js": () => import("./../src/pages/home/ReceiveFast.js" /* webpackChunkName: "component---src-pages-home-receive-fast-js" */),
  "component---src-pages-home-send-links-js": () => import("./../src/pages/home/SendLinks.js" /* webpackChunkName: "component---src-pages-home-send-links-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

